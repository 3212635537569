var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"card card-custom card-stretch gutter-b"},[_c('div',{staticClass:"card-body pt-3 pb-0"},[_c('b-collapse',{attrs:{"visible":"","id":"collapse-filter"}},[_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-lg-3"},[_c('b-form-group',{attrs:{"label":"Parametr","label-for":"parameter"}},[_c('b-form-input',{attrs:{"id":"parameter","placeholder":"Podaj nazwę"},model:{value:(_vm.filters.parameter),callback:function ($$v) {_vm.$set(_vm.filters, "parameter", $$v)},expression:"filters.parameter"}})],1)],1),_c('div',{staticClass:"col-lg-3"},[_c('b-form-group',{attrs:{"label":"Metoda badawcza","label-for":"method"}},[_c('b-form-input',{attrs:{"id":"method","placeholder":"Podaj nazwę"},model:{value:(_vm.filters.method),callback:function ($$v) {_vm.$set(_vm.filters, "method", $$v)},expression:"filters.method"}})],1)],1),_c('div',{staticClass:"col-lg-3"},[_c('b-form-group',{attrs:{"label":"Matryca","label-for":"number"}},[_c('b-form-input',{attrs:{"id":"number","placeholder":"Podaj matrycę"},model:{value:(_vm.filters.matrix),callback:function ($$v) {_vm.$set(_vm.filters, "matrix", $$v)},expression:"filters.matrix"}})],1)],1)])])],1)]),_c('b-button',{staticClass:"mt-3 mb-4",attrs:{"variant":"primary"},on:{"click":_vm.addLine}},[_vm._v("Dodaj")]),_c('div',{staticClass:"table-responsive"},[_c('v-data-table',{staticClass:"table custom-table table-head-custom table-vertical-center table-head-bg table-borderless",attrs:{"dense":"","headers":_vm.computedHeaders,"items":_vm.dataToShow,"item-key":"id","mobile-breakpoint":"0","footer-props":{
	          showFirstLastPage: true,
	          itemsPerPageText: 'Wyników na stronę',
	          pageText: '{0}-{1} na {2}',
	          itemsPerPageOptions: [10, 30, 50, 100]
	        },"items-per-page":10},scopedSlots:_vm._u([{key:"body",fn:function(ref){
	        var items = ref.items;
	        var headers = ref.headers;
return [_c('tbody',[_vm._l((items),function(order,index){return [_c('tr',{key:order.id,attrs:{"data-index":index}},[_c('td',[_c('span',{staticClass:"text-dark-75 d-block font-size-md"},[_vm._v(" "+_vm._s(order.parameter)+" ")])]),_c('td',[_c('span',{staticClass:"text-dark-75 d-block font-size-md"},[_vm._v(" "+_vm._s(order.method)+" ")])]),_c('td',[_c('span',{staticClass:"text-dark-75 d-block font-size-md"},[_vm._v(" "+_vm._s(_vm.matrixLabels[order.matrix])+" ")])]),_c('td',[_c('span',{staticClass:"text-dark-75 d-block font-size-md"},[_vm._v(" "+_vm._s(order.createdAt)+" ")])]),_c('td',[_c('a',{staticClass:"btn btn-light btn-xs mr-2",attrs:{"title":"Edytuj wpis"},on:{"click":function($event){return _vm.editLabOrder(order.id)}}},[_c('i',{staticClass:"fa fa-edit p-0 text-primary"})]),_c('a',{staticClass:"btn btn-light btn-xs mr-2",attrs:{"title":"Usuń wpis"},on:{"click":function($event){return _vm.removeLabOrder(order.id)}}},[_c('i',{staticClass:"fa fa-trash p-0 text-primary"})])])])]})],2)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }