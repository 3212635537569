<template>
	<div>
		<div class="card card-custom card-stretch gutter-b">
			<div class="card-body pt-3 pb-0">
	      <b-collapse visible id="collapse-filter">
	        <div class="row mb-3">
		        <div class="col-lg-3">
		        	<b-form-group label="Parametr" label-for="parameter">
	              <b-form-input
	                id="parameter"
	                placeholder="Podaj nazwę"
	                v-model="filters.parameter"
	              ></b-form-input>
	            </b-form-group>
		        </div>
		        <div class="col-lg-3">
		        	<b-form-group label="Metoda badawcza" label-for="method">
	              <b-form-input
	                id="method"
	                placeholder="Podaj nazwę"
	                v-model="filters.method"
	              ></b-form-input>
	            </b-form-group>
		        </div>
		        <div class="col-lg-3">
		        	<b-form-group label="Matryca" label-for="number">
	              <b-form-input
	                id="number"
	                placeholder="Podaj matrycę"
	                v-model="filters.matrix"
	              ></b-form-input>
	            </b-form-group>
		        </div>
	        </div>
	  		</b-collapse>
			</div>
		</div>

		<!-- <router-link
      to="/lab/researchLine/add/"
      v-slot="{ href, navigate, isActive, isExactActive }"
      custom
    > -->
      <b-button @click="addLine" variant="primary" class="mt-3 mb-4"
        >Dodaj</b-button
      >
    <!-- </router-link> -->

		<div class="table-responsive">
	    <v-data-table
	        dense
	        :headers="computedHeaders"
	        :items="dataToShow"
	        class="table custom-table table-head-custom table-vertical-center table-head-bg table-borderless"
	        item-key="id"
	        mobile-breakpoint="0"
	        :footer-props="{
	          showFirstLastPage: true,
	          itemsPerPageText: 'Wyników na stronę',
	          pageText: '{0}-{1} na {2}',
	          itemsPerPageOptions: [10, 30, 50, 100]
	        }"
	        :items-per-page="10"
	      >
	      	<template v-slot:body="{ items, headers }">
	      		<tbody>
	      			<template v-for="(order, index) in items">
	      				<tr
	      					:key="order.id"
	      					:data-index="index"
	      				>
	      					<td>
	      						<span class="text-dark-75 d-block font-size-md">
	      							{{ order.parameter }}
	      						</span>
	      					</td>
	      					<td>
	      						<span class="text-dark-75 d-block font-size-md">
	      							{{ order.method }}
	      						</span>
	      					</td>
	      					<td>
	      						<span class="text-dark-75 d-block font-size-md">
	      							{{ matrixLabels[order.matrix] }}
	      						</span>
	      					</td>
	      					<td>
	      						<span class="text-dark-75 d-block font-size-md">
	      							{{ order.createdAt}}
	      						</span>
	      					</td>
	      					<td>
	      						<a
	      						    class="btn btn-light btn-xs mr-2"
	      						    title="Edytuj wpis"
	      						    @click="editLabOrder(order.id)"
	      						>
	      						  <i class="fa fa-edit p-0 text-primary"></i>
	      						</a>

										<a
										    class="btn btn-light btn-xs mr-2"
										    title="Usuń wpis"
										    @click="removeLabOrder(order.id)"
										>
										  <i class="fa fa-trash p-0 text-primary"></i>
										</a>      					
	      					</td>
	      				</tr>
	      			</template>
	      		</tbody>
	      	</template>
			</v-data-table>   
		</div>
	</div>
</template>

<script>
	import { mapGetters } from "vuex";
	import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
	import Swal from "sweetalert2";

	export default {
		name: "orderPanelLabListing",
		mounted() {
			this.$store.dispatch("researchLinesList");

			this.$store.dispatch(SET_BREADCRUMB, [
      	{ title: "Panel laboratorium - Lista dostepnych kiernuków badań", route: "orderPanelLab" }
    	]);
		},
		data() {
			return {
				filters: {
					parameter: "",
					method: "",
					matrix: ""
				},
				matrixLabels: {
					"serologia": "Serologia",
					"weterynaria": "Weterynaria",
					"pasza": "Pasza",
					"zywnosc": "Żywność",
					"woda": "Woda",
					"srodowisko": "Środowisko",
					"urzedowe": "Urzędowe"
				}
			}
		},
		computed: {
		  ...mapGetters([
		    "researchLinesRaw"
		  ]),
		  dataToShow() {
		  	// console.log(this.researchLinesRaw)

		  	if ( this.filters.parameter.trim().length == 0 && this.filters.method.trim().length == 0 && this.filters.matrix.trim().length == 0 )
		  		return this.researchLinesRaw;

		  	let filters = [];

	  		Object.keys(this.filters).forEach( key => {
	  			if ( this.filters[key] && this.filters[key].trim().length > 0 )
	  				filters.push( key )
	  		} );

		  	return this.researchLinesRaw.filter( line => {

		  		let lineFilters = [...filters];

		  		for ( const index in lineFilters )
		  		{
		  			let filterKey = lineFilters[index];

		  			if ( filterKey == 'parameter' )
		  			{
		  				if ( line.parameter.toLowerCase().trim().includes( this.filters.parameter.toLowerCase().trim() ) )
		  					lineFilters[index] = true;
		  				else
		  					lineFilters[index] = false;
		  			}

		  			if ( filterKey == 'method' )
		  			{
		  				if ( line.method.toLowerCase().trim().includes( this.filters.method.toLowerCase().trim() ) )
		  					lineFilters[index] = true;
		  				else
		  					lineFilters[index] = false;
		  			}

		  			if ( filterKey == 'matrix' )
		  			{
		  				if ( line.matrix.toLowerCase().trim().includes( this.filters.matrix.toLowerCase().trim() ) )
		  					lineFilters[index] = true;
		  				else
		  					lineFilters[index] = false;
		  			}
		  		}

		  		return lineFilters.reduce((acc, val) => acc &= val, true);

		  	});
		  },
			computedHeaders() {
		  	return [
		  		{
		  		  text: "Parametr",
		  		  value: "parameter",
		  		  style: "min-width: 200px;"
		  		},
		  		{
		  		  text: "Metoda badawcza",
		  		  value: "method",
		  		  style: "min-width: 200px;"
		  		},
		  		{
		  		  text: "Matryca",
		  		  value: "matrix",
		  		  style: "min-width: 200px;"
		  		},
		  		{
		  		  text: "Data utworzenia",
		  		  value: "createdAt",
		  		  style: "min-width: 200px;"
		  		},
		  		{
		  		  text: "Akcje",
		  		  value: "actions",
		  		  style: "min-width: 200px;"
		  		},
		  	]
		  },
		},
		methods: {
			addLine() {
				this.$router.push('/lab/researchLine/add/')
			},
			editLabOrder(id) {
				this.$router.push("/lab/researchLine/edit/" + id);
			},
			removeLabOrder(id) {
				Swal.fire({
				  title: "Na pewno usunąć?",
				  text: "Na pewno chcesz to zrobić?",
				  icon: "warning",
				  confirmButtonClass: "btn btn-danger",
				  cancelButtonClass: "btn btn-primary",
				  showCancelButton: true,
				  confirmButtonText: "Tak",
				  cancelButtonText: "Nie",
				}).then((result) => {
				  if (result.isConfirmed) {
				    this.$store.dispatch("researchLinesDelete", { id });
				    Swal.fire("Gotowe!", "Usunięto wpis.", "success");
				  }
				});
			}
		}
	}
</script>